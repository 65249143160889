
import { Component, Vue } from 'nuxt-property-decorator'
@Component({
  components: {
    FullScreenImage: () => import('~/components/FullScreenImage.vue')
  }
})
export default class extends Vue {
  showFullScreen: boolean = false
}
