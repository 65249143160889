var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advantages-mobile",attrs:{"id":"advantages-info"}},[_c('div',{staticClass:"advantages-mobile_tabs"},[_c('div',{staticClass:"advantages-mobile_tabs_tab",class:{ 'active': _vm.isShown === '1' },on:{"click":function($event){_vm.isShown = '1'}}},[_vm._m(0),_vm._v(" "),(_vm.isShown === '1')?_c('div',{staticClass:"advantages-mobile_tabs_tab_description"},[_vm._m(1),_vm._v(" "),_vm._m(2)]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"advantages-mobile_tabs_tab",class:{ 'active': _vm.isShown === '2' },on:{"click":function($event){_vm.isShown = '2'}}},[_vm._m(3),_vm._v(" "),(_vm.isShown === '2')?_c('div',{staticClass:"advantages-mobile_tabs_tab_description"},[_vm._m(4),_vm._v(" "),_vm._m(5)]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"advantages-mobile_tabs_tab",class:{ 'active': _vm.isShown === '3' },on:{"click":function($event){_vm.isShown = '3'}}},[_vm._m(6),_vm._v(" "),(_vm.isShown === '3')?_c('div',{staticClass:"advantages-mobile_tabs_tab_description"},[_vm._m(7),_vm._v(" "),_vm._m(8)]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advantages-mobile_tabs_tab_header"},[_c('div',{staticClass:"advantages-mobile_tabs_tab_header_head"},[_c('img',{staticClass:"advantages-mobile_tabs_tab_header_head__image",attrs:{"src":require("assets/img/info-block/advantages-data-icon.svg"),"alt":"tab-icon"}}),_vm._v(" "),_c('h4',{staticClass:"advantages-mobile_tabs_tab_header_head__title"},[_vm._v("\n            Актуальные данные о штрафах из официальных источников\n          ")])]),_vm._v(" "),_c('img',{staticClass:"advantages-mobile_tabs_tab_header__arrow",attrs:{"src":require("assets/img/info-block/advantages-arrow.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advantages-mobile_tabs_tab_description_info"},[_c('p',{staticClass:"advantages-mobile_tabs_tab_description_info__text"},[_vm._v("\n            Многие другие онлайн-сервисы получают данные о задолженностях от банков-партнеров. Как в глухом телефоне, некоторые штрафы ищутся с задержкой или не отображаются совсем, даже если водитель уверен, что “поймал камеру”. Сервис ГосОплата, в отличие от остальных, заботится о том, чтобы вы всегда были в курсе своих нарушений и могли своевременно их оплатить с скидкой 50%. Портал работает непосредственно с базами данных ГИБДД, ФНС, ФССП и ГИС ГМП, что позволяет нам предоставлять самые точные и полные сведения о наличии штрафов\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advantages-mobile_tabs_tab_description_image-block tab-1"},[_c('div',{staticClass:"advantages-mobile_tabs_tab_description_image-block_gradient"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advantages-mobile_tabs_tab_header"},[_c('div',{staticClass:"advantages-mobile_tabs_tab_header_head"},[_c('img',{staticClass:"advantages-mobile_tabs_tab_header_head__image",attrs:{"src":require("assets/img/info-block/advantages-notification-icon.svg"),"alt":"tab-icon"}}),_vm._v(" "),_c('h4',{staticClass:"advantages-mobile_tabs_tab_header_head__title"},[_vm._v("\n            Уведомления о новых штрафах и статусе погашенных\n          ")])]),_vm._v(" "),_c('img',{staticClass:"advantages-mobile_tabs_tab_header__arrow",attrs:{"src":require("assets/img/info-block/advantages-arrow.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advantages-mobile_tabs_tab_description_info"},[_c('p',{staticClass:"advantages-mobile_tabs_tab_description_info__text"},[_vm._v("\n            Наш сервис предоставляет удобную возможность оперативно получать уведомления о новых штрафах ГИБДД и отслеживать статус их оплаты. Теперь вам не нужно беспокоиться о том, что важное уведомление может быть пропущено или штраф останется неоплаченным. Система автоматически отправляет уведомления в браузере, мобильном приложении, в WhatsApp или на электронную почту сразу после появления нового штрафа или изменения его статуса, обеспечивая вам полную осведомлённость о текущей ситуации. Это гарантирует, что вы сможете своевременно реагировать на любые штрафы, избегая накопления задолженностей и связанных с этим дополнительных расходов\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advantages-mobile_tabs_tab_description_image-block tab-2"},[_c('div',{staticClass:"advantages-mobile_tabs_tab_description_image-block_gradient"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advantages-mobile_tabs_tab_header"},[_c('div',{staticClass:"advantages-mobile_tabs_tab_header_head"},[_c('img',{staticClass:"advantages-mobile_tabs_tab_header_head__image",attrs:{"src":require("assets/img/info-block/advantages-payment-icon.svg"),"alt":"tab-icon"}}),_vm._v(" "),_c('h4',{staticClass:"advantages-mobile_tabs_tab_header_head__title"},[_vm._v("\n            Оплата задолженностей со скидкой 50%\n          ")])]),_vm._v(" "),_c('img',{staticClass:"advantages-mobile_tabs_tab_header__arrow",attrs:{"src":require("assets/img/info-block/advantages-arrow.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advantages-mobile_tabs_tab_description_info"},[_c('p',{staticClass:"advantages-mobile_tabs_tab_description_info__text"},[_vm._v("\n            Наш сервис предоставляет возможность оплачивать штрафы ГИБДД со скидкой 50% при условии, что задолженность будет погашена в течение 20 дней с момента получения постановления. Это не только позволяет сэкономить средства, но и помогает избежать дополнительных штрафов и пеней, которые могут быть начислены за просрочку платежа. Воспользовавшись нашим сервисом, вы можете оплатить штраф онлайн в любое удобное для вас время, не выходя из дома и не тратя время на очереди. Своевременное погашение задолженностей помогает избежать возможных неприятностей, таких как аресты счетов, ограничения на выезд за границу и другие меры принудительного взыскания, которые могут быть применены к неплательщикам\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advantages-mobile_tabs_tab_description_image-block tab-3"},[_c('div',{staticClass:"advantages-mobile_tabs_tab_description_image-block_gradient"})])
}]

export { render, staticRenderFns }