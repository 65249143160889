// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/info-block/advantages-data.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/img/info-block/advantages-notification.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../assets/img/info-block/advantages-payment.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".advantages-mobile_tabs_tab_description .tab-1[data-v-bbd3065e]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.advantages-mobile_tabs_tab_description .tab-2[data-v-bbd3065e]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.advantages-mobile_tabs_tab_description .tab-3[data-v-bbd3065e]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
